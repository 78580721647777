import React, {useContext, useEffect, useRef,useState} from 'react'
import {Box ,
        Typography,
        Accordion,AccordionDetails,AccordionSummary,makeStyles ,
 } from "@material-ui/core";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {Finalizar,NumeroTarjeta,TiposTarjeta,Carrera,Instrumento,Opciones,Programa,Modalidad,Horario,Cuatrimestre,Ubicacion,Telefonos,GenericCard} from './index'
import {useHelper} from '../hooks/useHelper'
import {useCards} from '../hooks/useCards'
import {Dialogos} from '../componentes/Dialogos'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Alert from '@mui/material/Alert';
import { useTimePickerDefaultizedProps } from '@mui/x-date-pickers/TimePicker/shared';
import {preguntas} from '../preguntas.json';
import DOMPurify from 'dompurify';

export const LayoutCards = ()=>{
    const { datos,
            tipoParticipante,
            cargando} = useCards()
    const {fechaCambioFormato,hacerScroll} = useHelper()
    const [abrirDialogo,setAbrirDialogo] = useState(true)
    const [error,setError] = useState(null)
    const [activarLoading,setActivarLoading] =useState(false)
    const solicitudPreparada =  useRef(false)
    const [datosConfirmados,setDatosConfirmados] = useState(false)

    const useStyle = makeStyles({
        selectpais: {
            background:datos.pais > 0 ? 'white' : '#D3D3D3',
            color:datos.pais > 0 ? 'black' : 'gray'
        },
        selectprovincia: {
            background:datos.provincia > 0 ? 'white' : '#D3D3D3',
            color:datos.provincia > 0 ? 'black' : 'gray'
        },
        prueba:{
            background:'black',
            color:'white'
        }
      });

    
    const classes = useStyle();

    if (cargando) {
        return <p>Cargando...</p>
    }

    return <Box sx={{display:'flex',maxWidth:'800px',marginLeft:'auto',marginRight:'auto', flexDirection:'column', padding:'1px', justifyContent:'center',background:'#E7EBF0'}}> 
        <div>
            <img src={process.env.REACT_APP_IMAGEN_EVENTO} alt="Convención" style={{width:'100%'}} />
            
            <GenericCard titulo={process.env.REACT_APP_TITULO_EVENTO} id='tl-ubicacion' 
                        mostrar={true} 
                        error={false}
                        subtitulo={tipoParticipante=='general' ? 'Para inscribirse a éste evento por favor siga las instrucciones' : 'Inscripción de contingentes y otros'}
                        mensajeError = {'nada'}
                        >
                            <Opciones/>
             </GenericCard>
       

            <Dialogos open={datosConfirmados} 
                    titulo='Para continuar por favor ingresá los datos de tu tarjeta y aceptá los términos y condiciones' 
                    subtitulo='Subtítulo' 
                    procesarCancelar = {()=>alert(1)}
                    error={false}>
                            <h1>algo</h1>
            </Dialogos>
            <PreguntasFrecuentes/>
        </div>
     </Box>
}


const PreguntasFrecuentes = ()=>{

    return  <Box sx={{padding:'16px'}}> 
         <Typography style={{padding:'20px'}} variant='h6' align='left' color='primary'>
          Preguntas frecuentes
      </Typography>
        {preguntas.map((item,index)=>{
            return  <SeccionPregunta
            pregunta={item.pregunta}
            respuesta={item.respuesta}
            id= {`pregunta-${index}`}
        />
        })}
</Box>
}

const SeccionPregunta = ({pregunta, respuesta,id})=>{

    return  <Accordion>
    <AccordionSummary sx={{padding:'50px'}}
      expandIcon={<ArrowDownwardIcon />}
      aria-controls={id}
      id={id}
    >
      <Typography variant='h6'>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(pregunta) }} />
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography align='left'  variant='subtitle1'>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(respuesta) }} />
      </Typography>
    </AccordionDetails>
  </Accordion>
}
