import ReactExport from "react-export-excel";
import React from 'react'
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import IconButton from '@mui/material/IconButton';
import { FormControlLabel,FormControl,RadioGroup,Radio,FormLabel,Tooltip,Box, Button} from "@material-ui/core";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Excel({datos,descripcion,columnas,titulo}){

    //const copia = [agregarFila(datos,descripcion),...datos]
        
       return <ExcelFile filename={titulo} element={
                <Button component="label" color="secondary" variant="contained" startIcon={<SaveAltIcon />}>
                    Generar archivo excel
                </Button>
        }>
            <ExcelSheet data={datos} name={descripcion}>
                {columnas.map(item=>{
                     return <ExcelColumn label={item.label} value={item.value}/>
                })}
            </ExcelSheet>
    </ExcelFile>
    }
    
    function agregarFila(datos,descripcion){
        return {...datos[0],
                rubro:descripcion,
                mes_largo:null,
                año:null,
                egreso_numerico:null,
                codigo_rubro:null}
    }