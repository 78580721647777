import React, {useContext, useState,useEffect} from 'react'
import {Box,Button,TextField,Typography} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import Filter1TwoToneIcon from '@mui/icons-material/LooksOne';
import Filter2TwoToneIcon from '@mui/icons-material/LooksTwo';
import WhatsappRoundedIcon from '@mui/icons-material/WhatsappRounded';
import {Dialogos} from './Dialogos'
import {TiposPago} from './TiposPago'
import {NuevaInscripcion} from './NuevaInscripcion'
import {Alert} from '@mui/material'
import { GenericCard } from './GenericCard';
import {Inscripcion} from './Inscripcion'
import Axios from 'axios'
import Swal from 'sweetalert2';

export const Opciones = ({tipoInscripcion})=>{
    const {tipoParticipante,
            reinicializar,
            codigoFinal,
            buscarStatusMinistro,
            buscarStatusCupon,
            solicitarLinkConstancia,
            cuponUtilizado,
            nro_cupon} = useContext(formularioContext)
    const [operacion,setOperacion]= useState(tipoInscripcion || null)

    useEffect(()=>{
        if(codigoFinal){
            setOperacion(null) 
            reinicializar()
        }
    },[codigoFinal]) // cada vez que hay una inscripción va a cambiar el códigoFinal que es el código web del alta
    
    const cancelarInscripcion = ()=>{
        setOperacion(null)
        reinicializar()
    }

return  <Box sx={{display:'flex',flexDirection:'column'}}>
                {tipoParticipante=='general' && <>
                    <Box sx={{padding:'1rem'}}>
                        <Button variant='contained' onClick={()=>setOperacion('pagar')}  startIcon={<Filter1TwoToneIcon style={{fontSize:'2rem'}}/>} fullWidth color='primary'>Realice el pago aquí</Button>
                    </Box>
                    <Box sx={{padding:'1rem'}}>
                        <Button variant='contained' onClick={()=>setOperacion('inscribirse')} size="large" startIcon={<Filter2TwoToneIcon style={{fontSize:'2rem'}}/>} fullWidth color='primary'>Inscríbase aquí</Button>
                    </Box>
                    <Alert variant="outlined" severity="info" sx={{marginTop:'1rem'}}>
                        Para ingresar al evento debe presentar su constancia de inscripción impresa o en su teléfono celular
                        <Button variant='text' style={{textTransform: 'none'}} onClick={()=>setOperacion('constancia')} size="large" color='primary'>Consulte o solicite su constancia de inscripción aquí</Button>
                    </Alert>
                    <Box>
                        <Button variant='text' style={{textTransform: 'none'}} onClick={()=>setOperacion('consulta')} size="large" color='secondary'>¿Necesita ayuda?</Button>
                    </Box>
                </>}

                {tipoParticipante=='contingente' && <>
                    <Box sx={{padding:'1rem'}}>
                        <Button variant='contained' onClick={()=>setOperacion('inscribirse')} size="large" startIcon={<Filter1TwoToneIcon style={{fontSize:'2rem'}}/>} fullWidth color='primary'>Inscríbase aquí</Button>
                    </Box>
                    <Alert variant="outlined" severity="info" sx={{marginTop:'1rem'}}>
                        Para ingresar al evento debe presentar su constancia de inscripción impresa o en su teléfono celular
                        <Button variant='text' style={{textTransform: 'none'}} onClick={()=>setOperacion('constancia')} size="large" color='primary'>Consulte o solicite su constancia de inscripción aquí</Button>
                    </Alert>
                    <Box sx={{padding:'2rem'}}>
                        <Button variant='text' style={{textTransform: 'none'}} onClick={()=>setOperacion('consulta')} size="large" color='secondary'>¿Necesita ayuda?</Button>
                    </Box>
                </>}

                {/*<Box sx={{padding:'0.5rem'}}>
                    <Button onClick={()=>setOperacion('constancia')} fullWidth variant='outlined' color='secondary'>Descargue su constancia de inscripción aquí</Button>
</Box>*/}
        <Dialogos open={operacion=='pagar' && tipoParticipante=='general'} 
                    titulo='Seleccione como desea pagar' 
                    procesarCancelar = {()=>setOperacion(null)}
                    >
              <TiposPago/>
        </Dialogos>

        <Dialogos open={operacion=='pagar' && tipoParticipante=='contingente'} 
                    titulo='Instrucciones de pago para contingentes' 
                    texto='En el caso de contingentes el pago se envía únicamente por transferencia bancaria' 
                    procesarCancelar = {()=>setOperacion(null)}
                    >
                <p>Por favor contacte a la mesa de ayuda o a la ofina UAD</p>
        </Dialogos>

        <Dialogos open={operacion=='inscribirse'} 
                    titulo={`${process.env.REACT_APP_TITULO_EVENTO} - Inscripción`}
                    fullscreen={true}
                    closeHeader
                    procesarCancelar = {cancelarInscripcion}
                   >
              <Inscripcion/>
        </Dialogos>

        <Dialogos open={operacion=='preferencia'} 
                    titulo={`COMIENZO NUEVO TIPO DE INSCRIPCION por preferencia MP`}
                    fullscreen={true}
                    closeHeader
                    procesarCancelar = {cancelarInscripcion}
                   >
              <NuevaInscripcion id_evento={process.env.REACT_APP_ID_EVENTO}/>
        </Dialogos>

        <Dialogos open={nro_cupon!='' && !cuponUtilizado} 
                    titulo={`${process.env.REACT_APP_TITULO_EVENTO} - Inscripción`} 
                    fullscreen={true}
                   >
               <Typography style={{marginTop:'0.5rem',marginBottom:'0.5rem'}} variant="h6">Cupón {nro_cupon}</Typography>
              <Inscripcion/>
              <Box sx={{padding:'2rem',display:'flex',justifyContent:'center'}}>
                        <Button variant='text' style={{textTransform: 'none'}} onClick={()=>setOperacion('consulta')} size="large" color='secondary'>¿Necesita ayuda?</Button>
              </Box>
        </Dialogos>

        <Dialogos open={nro_cupon!='' && cuponUtilizado} 
                    titulo={`${process.env.REACT_APP_TITULO_EVENTO} - Inscripción`} 
                    fullscreen={true}
                   >
                <Typography style={{marginTop:'0.5rem',marginBottom:'0.5rem'}} variant="h6">Cupón {nro_cupon}</Typography>
                <Cupon nro_cupon={nro_cupon} buscarStatusCupon={buscarStatusCupon} solicitarLinkConstancia={solicitarLinkConstancia}/>
                <Box sx={{padding:'2rem',display:'flex',justifyContent:'center'}}>
                        <Button variant='text' style={{textTransform: 'none'}} onClick={()=>setOperacion('consulta')} size="large" color='secondary'>¿Necesita ayuda?</Button>
                </Box>
        </Dialogos>

        <Dialogos open={operacion=='consulta'} 
                    titulo='Mesa de ayuda' 
                    closeHeader
                    procesarCancelar = {()=>setOperacion(null)}
                   >
              <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',textAlign:'center'}}>
                    <a href="https://api.whatsapp.com/send?phone=541126188236" target="_blank">
                        <WhatsappRoundedIcon sx={{ fontSize: 60, color:'green' }}/>
                        <Typography style={{marginTop:'0.5rem',marginBottom:'0.5rem'}} variant="h6">Abrir el chat de whatsapp</Typography>
                        <Typography variant="caption">011 2618-8236</Typography>
                    </a>
              </Box>
        </Dialogos>

        <Dialogos open={operacion=='constancia'} 
                    titulo={`${process.env.REACT_APP_TITULO_EVENTO} - ${operacion=='constancia' ? ' Consulta o solicitud de constancia de inscripción' : ' Estado de su inscripción'}`} 
                    fullscreen={true}
                    closeHeader
                    procesarCancelar = {()=>setOperacion(null)}
                   >
            <Alert sx={{justifyContent:'center'}} severity="xxx" variant='xxx'>
                Para consultar o solicitar su constancia de inscripción ingrese su DNI
            </Alert>                    
              <Documento buscarStatusMinistro={buscarStatusMinistro} solicitarLinkConstancia={solicitarLinkConstancia}/>
        </Dialogos>
    </Box>
    
}

const Documento = ({buscarStatusMinistro,solicitarLinkConstancia})=>{
    const regex_solo_numeros = /^[0-9\b]+$/;

    const [documento,setDocumento] = useState('')
    const [respuesta,setRespuesta] = useState(null)

  /*  const enviarMail = async ()=>{

        try{
            const {data} = await Axios.post(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/tablasgenerales/enviarmail`:`https://uadapi.uad.digital/api/tablasgenerales/enviarmail`,{mensaje:'esto es una prueba'})
            
            console.log(JSON.stringify(data))

        }
        catch(err){
            console.log(JSON.stringify(err))
        }
    }
*/
    useEffect(()=>{
        setRespuesta(null)
    },[documento])

    const handleChangeDocumento = (event) => {

        if(regex_solo_numeros.test(event.target.value.trim()) || event.target.value.trim()==''){
            setDocumento(event.target.value);
        }

    };

    const validarDNI = ()=>{
        buscarStatusMinistro(documento)
        .then(respuesta=>{
            setRespuesta(respuesta)
        })
        .catch(err=>{
            console.log(err)
            setRespuesta(err.message)
        })
    }

    const solicitarConstancia = async ()=>{
        try{
            const respuesta = await solicitarLinkConstancia(documento,'nro_documento')
            Swal.fire({
                html:`<p>${respuesta.mensaje}</p>`,
                icon: 'info',
                confirmButtonColor: '#3085d6',
            })
            setDocumento('')
 
        }catch(err){
            alert(err.message)
        }

    }

    return <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    <Box sx={{marginTop:'1rem',marginBottom:'1rem'}}>
            <TextField autoFocus value={documento} onChange={handleChangeDocumento} fullWidth id="ub-dni" inputProps={{maxLength: 9,placeholder:'DNI'}} label="Ingrese su DNI" variant="outlined" />
    </Box>

    {respuesta && <Alert sx={{justifyContent:'center'}} severity="info" variant='outlined'>
            {respuesta.titulo && <> {/* lo encontró inscripto y trajo el detalle*/}
                <p>Estado: {respuesta.status}</p>
                <p>Evento: {respuesta.titulo}</p>
                <p>Detalle: {respuesta.detalle}</p>
                <p>Fecha de inscripción: {respuesta.fecha}</p>
                <p>Cantidad de inscriptos: {respuesta.inscriptos}</p>
                <Button onClick={solicitarConstancia} variant='contained' color='secondary'>Solicitar constancia de inscripción</Button>
            </>}
            {!respuesta.titulo && <p>{respuesta}</p>}{/*significa que no encontró inscripto al nro documento*/} 
    </Alert>}
    
    {documento && !respuesta && <Box sx={{padding:'2rem'}}>
            <Button disabled={documento.trim()=='' || documento.trim().length<7} onClick={validarDNI} variant='contained' color='primary'>Continuar</Button>
    </Box>}
    {respuesta && <Box sx={{padding:'2rem'}}>
            <Button onClick={()=>setDocumento('')} variant='contained' color='primary'>Borrar</Button>
    </Box>}
</Box>
}

const Cupon = ({buscarStatusCupon,solicitarLinkConstancia,nro_cupon})=>{
    const regex_solo_numeros = /^[0-9\b]+$/;

    const [respuesta,setRespuesta] = useState(null)

    useEffect(()=>{
        if(nro_cupon!=''){
            buscarStatusCupon(nro_cupon)
            .then(res=>{
                setRespuesta(res)
            })
        }
    },[nro_cupon])

    const solicitarConstancia = async ()=>{
        try{
            const respuesta = await solicitarLinkConstancia(nro_cupon,'cupon')
            Swal.fire({
                html:`<p>${respuesta.mensaje}</p>`,
                icon: 'info',
                confirmButtonColor: '#3085d6',
            })
 
        }catch(err){
            alert(err.message)
        }

    }

    return <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    {respuesta && <Alert sx={{justifyContent:'center'}} severity="info" variant='outlined'>
            {respuesta.titulo && <> {/* lo encontró inscripto y trajo el detalle*/}
                <p>Estado: {respuesta.status} (Con cupón {nro_cupon})</p>
                <p>Evento: {respuesta.titulo}</p>
                <p>Detalle: {respuesta.detalle}</p>
                <p>Solicitante: {respuesta.solicitanteDni} / {respuesta.solicitanteEmail}</p>
                <p>Fecha de inscripción: {respuesta.fecha}</p>
                <p>Cantidad de inscriptos: {respuesta.inscriptos}</p>
                <Button onClick={solicitarConstancia} variant='contained' color='secondary'>Solicitar constancia de inscripción</Button>
            </>}
            {!respuesta.titulo && <p>{respuesta}</p>}{/*significa que no encontró inscripto al nro documento*/} 
    </Alert>}
</Box>
}