export const preguntas = [
    {pregunta:'¿Dónde se realiza la Convención Nacional?',
        respuesta:'La Convención se llevará a cabo en Av. Eva Perón 1040 auditorio Catedral de la Fe CABA.'
    },
    {pregunta:'¿Quienes pueden participar?',
        respuesta:'La Convención Nacional UAD es para todos los ministros, pastores, líderes, obreros y para todos los que quieran participar de la misma. Será un tiempo de familia y koinonia.'
    },
    {pregunta:'¿Qué incluye la entrada?',
        respuesta:`La entrada otorga el acceso a la Convención Nacional UAD y toda actividad que se haga dentro del marco de la actividad.`
    },
{pregunta:'¿Se puede transferir la entrada a otra personas?',
        respuesta:'Para transferir la entrada a otra persona debe comunicarse con la oficina de la UAD enviando mensaje al <span class="phones-special">+549 11 2618-8236</span>.'
    },
{pregunta:'¿Cuál es la política de reembolso?',
        respuesta:'Si usted adquiere la entrada y por asuntos mayores no puede asistir deberá enviar un mensaje a <span class="phones-special">+549 11 2618-8236</span>.'
    },
{pregunta:'¿A partir de qué edad pagan los menores?',
        respuesta:'Aquellos mayores de 13 años deberán abonar la entrada.'
    },
{pregunta:'¿Los lugares están asignados?',respuesta:'Los lugares no están asignados, el ingreso será por orden de llegada. No se podrá reservar o guardar lugar para familias, amigos o conocidos. '},
{pregunta:'¿Con quién me puedo contactar si tengo una pregunta?'
,respuesta:'En caso de tener una pregunta específica puede escribir al WhatsApp de la UAD <span class="phones-special">+549 11 2618-8236</span>.'}
]