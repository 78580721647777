import React, {useContext,useEffect} from 'react'
import {TextField,Button,DialogTitle,DialogContentText,DialogContent,DialogActions,Dialog} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CheckIcon from '@mui/icons-material/Check';
import { Height } from '@mui/icons-material';
import { useHelper } from '../hooks/useHelper';
export const Dialogos = ({open,
                          procesarResultado,
                          titulo,
                          children,
                          texto,
                          procesarCancelar,
                          error,
                          textoAceptar,
                          textoCancelar,
                          fullscreen,
                          closeHeader,
                          style,
                          backdropProps,
                          soloCheckCerrar,
                          contentStyle})=>{
    const {datos,handleChangeCarrera} = useContext(formularioContext)
    const {scrollTop} = useHelper()

    useEffect(()=>{
      scrollTop();
    },[])

    return     <div>
    <Dialog style={style} fullScreen={fullscreen || false} open={open} onClose={procesarResultado}
        BackdropProps={backdropProps}
    >
      {titulo && <DialogTitle style={{background:'rgb(232 240 254)'}} >{titulo}
          {closeHeader && <Button style={{width:'100%',justifyContent:'center'}} title='Cerrar' onClick={procesarCancelar}>Cerrar <CloseSharpIcon/></Button>}
      </DialogTitle>}
      <DialogContent style={contentStyle}>
        <DialogContentText>
            {texto}
        </DialogContentText>
        {children}
        {error && <p style={{color:'red'}}>{error}</p>}
      </DialogContent>
      <DialogActions>
        {soloCheckCerrar && procesarCancelar && <Button title='Cerrar' onClick={procesarCancelar}><CheckIcon/></Button>}
        {procesarCancelar && !soloCheckCerrar && <Button onClick={procesarCancelar}>{textoCancelar || 'Cancelar'}</Button>}
        {procesarResultado && <Button variant="outlined" color='primary' onClick={procesarResultado}>{textoAceptar ? textoAceptar : 'Aceptar'}</Button>}
      </DialogActions>
    </Dialog>
  </div>
}