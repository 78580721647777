import React, { useState } from 'react'
import {Box,Button,Typography} from "@material-ui/core";

import WhatsappRoundedIcon from '@mui/icons-material/WhatsappRounded';
import {Dialogos} from './Dialogos'


export const MesaDeAyuda = ()=>{

    const [abrir,setAbrir]= useState(false)


return  <Box sx={{display:'flex',flexDirection:'column'}}>
    
               
                    <Box sx={{padding:'2rem'}}>
                        <Button variant='text' style={{textTransform: 'none'}} onClick={()=>setAbrir(true)} size="large" color='secondary'>¿Necesita ayuda?</Button>
                    </Box>
               
        <Dialogos open={abrir} 
                    titulo='Mesa de ayuda' 
                    closeHeader
                    procesarCancelar = {()=>setAbrir(false)}
                   >
              <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',textAlign:'center'}}>
                    <a href="https://api.whatsapp.com/send?phone=541126188236" target="_blank">
                        <WhatsappRoundedIcon sx={{ fontSize: 60, color:'green' }}/>
                        <Typography style={{marginTop:'0.5rem',marginBottom:'0.5rem'}} variant="h6">Abrir el chat de whatsapp</Typography>
                        <Typography variant="caption">011 2618-8236</Typography>
                    </a>
              </Box>
        </Dialogos>
    </Box>
}
