import React, { useEffect,useState,useContext } from "react"

import { formularioContext } from '../contextos/FormularioContext'


export const useCards = ()=>{

    const { datos,tipoParticipante,
        cargando,datosPersonalesOK,datosUbicacionOK,datosContactoOK,datosFinalesOK,datosPagoOK} = useContext(formularioContext)

    return {datos,cargando,datosPersonalesOK,datosUbicacionOK,datosContactoOK,datosFinalesOK,datosPagoOK,tipoParticipante}
}