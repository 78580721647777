import React,{useState,useContext, useEffect}  from 'react'
import { formularioContext } from '../contextos/FormularioContext'
import {LayoutCards} from './LayoutCards'
import {GenericCard} from './GenericCard'
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {useParams} from 'react-router-dom'
import Axios from 'axios'
import {MesaDeAyuda} from './MesaDeAyuda'
import {useHelper} from '../hooks/useHelper'
import Swal from 'sweetalert2';

export const Constancia = ({tipo})=>{
    const parametros = useParams()
    const [statusCupon,setStatusCupon]= useState(null)
    const {validarEmail} = useHelper()

    const buscarStatusCupon = async (nro_cupon)=>{

        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/inscripcion/statusporid/${parametros.id_alta_web}/*`:`https://uadapi.uad.digital/api/eventos/inscripcion/statusporid/${parametros.id_alta_web}/*`)
            setStatusCupon(data)
        }
        catch(err){
            console.log(err)
            Swal.fire({
                html:`<p>${err.response.data.message}</p>`,
                icon: 'error',
                confirmButtonColor: '#3085d6',
            })
        }
    }
    
    useEffect(()=>{
        if(parametros.id_alta_web){
            buscarStatusCupon()
        }
    },[parametros.id_alta_web])

    const solicitarLinkConstancia = async (documento,email)=>{

        const url = process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/cupon/inscripto/solicitud/link/${documento}/${parametros.id_alta_web}/${email}`:`https://uadapi.uad.digital/api/eventos/cupon/inscripto/solicitud/link/${documento}/${parametros.id_alta_web}/${email}`

        try{
            const {data} = await Axios.get(url)
           return data
        }
        catch(err){
            console.log(err)
            Swal.fire({
                html:`<p>${err.response.data.message}</p>`,
                icon: 'error',
                confirmButtonColor: '#3085d6',
            })
        }
    }

    if(!statusCupon || !parametros.id_alta_web || parametros?.id_alta_web==''){
        return <GenericCard titulo="Constancia de inscripción" 
        id='tl-finalizar' 
        mostrar={true}
        >
             <Alert sx={{justifyContent:'center'}} severity="error" variant='outlined'>
                <p>Código de inscripción: {parametros.id_alta_web}</p>
                <h4>No se encontraron datos para el código de inscripción ingresado</h4>
              </Alert>
              <MesaDeAyuda/>
        </GenericCard>
    }

    return <GenericCard titulo="Constancia de inscripción" 
                    id='tl-finalizar' 
                    mostrar={true}
                    subtitulo={parametros.titulo}
                    >   
                <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>                        
                        <InfoEvento statusCupon={statusCupon}/>        
                        <p>Para solicitar su constancia de inscripción ingrese su DNI y su e-mail</p>
                        <Documento validarEmail={validarEmail}
                        solicitarLinkConstancia={solicitarLinkConstancia}
                        />
                        <MesaDeAyuda/>
                </Box>
            </GenericCard>

}

const InfoEvento = ({statusCupon})=>{
return <Alert sx={{justifyContent:'center'}} severity="info" variant='outlined'>
    <p>Evento: {statusCupon.titulo}</p>
    <p>Detalle: {statusCupon.detalle}</p>
</Alert>
}

const Documento = ({validarEmail,solicitarLinkConstancia})=>{
    const regex_solo_numeros = /^[0-9\b]+$/;

    const [documento,setDocumento] = useState('')
    const [respuesta,setRespuesta] = useState(null)
    const [email,setEmail] = useState('')
    const [tocado,setTocado] = useState(false)

    //!email || validarEmail(email) || email.trim()==''

    useEffect(()=>{
        setRespuesta(null)
    },[documento])

    const procesarSolicitud = () =>{

        solicitarLinkConstancia(documento,email)
        .then(respuesta=>{
            Swal.fire({
                html:`<p>${respuesta.mensaje}</p>`,
                icon: 'info',
                confirmButtonColor: '#3085d6',
            })
            setDocumento('')
            setEmail('')
            setTocado(false)
        })
       

    }

    const handleChangeDocumento = (event) => {

        if(regex_solo_numeros.test(event.target.value.trim()) || event.target.value.trim()==''){
            setDocumento(event.target.value);
        }

    };

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    const datosConsultaOk = ()=>{

            if(!documento || documento.toString().trim()==''){
                return [false,`Falta completar el número de documento`]
            }

            if(documento.toString().trim().length>8){
                return [false,`El número de documento es demasiado largo`]
            }

            if(documento.toString().trim().length<7){
                return [false,`El número de documento requiere al menos 7 dígitos`]
            }

            if(!email || email.trim()==''){
                return [false,`Falta completar el e-mail`]
            }

            if(errorMail(email)){
                return [false,errorMail(email)]
            }

        return [true,null]
    }

    const errorMail = (email)=>{

        if(!email || validarEmail(email) || email.trim()==''){
            return null
        }else{
            return 'El e-mail ingresado parece incorrecto'
        }
    }


    /*const validarDNI = ()=>{
        buscarStatusMinistro(documento)
        .then(respuesta=>{
            setRespuesta(respuesta)
        })
        .catch(err=>{
            console.log(err)
            setRespuesta(err.message)
        })
    }*/

    return <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
        <Box sx={{marginTop:'1rem',marginBottom:'1rem',width:'280px'}}>
            <Box sx={{marginTop:'0.5rem'}}>
                <TextField autoFocus onKeyDown={()=>setTocado(true)} value={documento} onChange={handleChangeDocumento} fullWidth id="ub-dni" inputProps={{maxLength: 9,placeholder:'DNI'}} label="Ingrese su DNI" variant="outlined" />
            </Box>
            <Box sx={{marginTop:'0.5rem'}}>
                <TextField fullWidth 
                                id="con-email" 
                                type="email" 
                                inputProps={{maxLength: 200}} 
                                placeholder="nombre@ejemplo.com" 
                                value={email} 
                                onChange={handleChangeEmail} 
                                onKeyDown={()=>setTocado(true)}
                                label="Ingrese su e-mail" 
                                variant="outlined" />
                        {!datosConsultaOk()[0] && tocado && <Alert variant='outlined' severity="error">{datosConsultaOk()[1]}</Alert>}
            </Box>
        </Box>

    {datosConsultaOk()[0] && !respuesta && <Box sx={{padding:'2rem'}}>
            <Button disabled={documento.trim()=='' || documento.trim().length<7} onClick={procesarSolicitud} variant='contained' color='primary'>Solicitar constancia de inscripción</Button>
    </Box>}

    {respuesta && <Box sx={{padding:'2rem'}}>
            <Button onClick={()=>setDocumento('')} variant='contained' color='primary'>Borrar</Button>
    </Box>}
</Box>
}