import React,{useState} from 'react';
import ReactDOM from 'react-dom';
import {Alert,Grid,Button} from '@mui/material'
import {AyudaNP} from './AyudaNP'

//const estiloDefault = {width:'500px'}
export const AlertaPorPagos = ()=>{
    const [ayuda,setAyuda] = useState(false)

    return <div className='mt-4'>
    <Alert variant="outlined" severity="warning">
        Una vez hecho el pago debe inscribirse aquí mismo informando el número de operación que mercadopago le brindará.
        <p>Le pediremos ese <b>número de operación</b> para poder inscribirlo.</p>
        <Button style={{textTransform: 'none'}} onClick={()=>setAyuda(!ayuda)} size="small" color='primary' variant='text'>¿Qué es el número de operación?</Button>
    </Alert>
    <AyudaNP ayuda={ayuda} setAyuda={setAyuda}/>
</div>
}


