import React, {useContext,useState,useEffect} from 'react'
import {TextField, Grid, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import {DatosPersonales} from '../componentes/DatosPersonales'
import {RadioMinistroUad} from './RadioMinistroUad'

export const DatosPersonalesConyuge = ()=>{
    const { datos,
        handleChangeApellidoConyuge,
        handleChangeDocumentoConyuge,
        handleChangeSexoConyuge,
        handleChangeProvinciaConyuge,
        handleChangeConyugeMinistroUad,
        datosConyugeOk,
        handleChangeEmailConyuge,handleChangeEdadConyuge,
        handleChangeNombreConyuge} = useContext(formularioContext)
        
    const useStyle = makeStyles({
        selectpais: {
            background:datos.pais > 0 ? 'white' : 'gray',
            color:'black'
        },
        selectprovincia: {
            background:datos.provincia > 0 ? 'white' : 'gray',
            color:'black'
        },
      });

    const classes = useStyle();

    return <>
    {datos.ministroUad=='ministrouad' && <RadioMinistroUad/>}
    <DatosPersonales  handleChangeApellidoDinamico={handleChangeApellidoConyuge}
    handleChangeDocumentoDinamico = {handleChangeDocumentoConyuge}
    handleChangeSexoDinamico = {handleChangeSexoConyuge}
    handleChangeProvinciaDinamico = {handleChangeProvinciaConyuge}
    datosOkDinamico = {datosConyugeOk}
    handleChangeEdadDinamico = {handleChangeEdadConyuge}
    handleChangeEmailDinamico = {handleChangeEmailConyuge}
    handleChangeNombreDinamico = {handleChangeNombreConyuge}
    datosDinamicos = {{nombre:datos.conyuge.nombre,
                        apellido:datos.conyuge.apellido,
                        sexo:datos.conyuge.sexo,
                        edad:datos.conyuge.edad,
                        documento:datos.conyuge.documento,
                        id_provincia:datos.conyuge.id_provincia,
                        email:datos.conyuge.email}}
    />
    </>
}