import React, {useContext} from 'react'
import {TextField, Button, Box ,Typography,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'

export const RadioMinistroUad = ()=>{
    const { datos,handleChangeMinistroUADRadio } = useContext(formularioContext)

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        },
        radioButtonSexo:{
            display:'flex',
            flexDirection:'row'
        }
      });

    const classes = useStyle();

    return <Box sx={{marginTop:'-10px',textAlign:'center',marginBottom:'-10px'}}>
        <FormControl>
        <Typography variant='body2'>¿Su conyuge es ministro UAD?</Typography>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={datos.conyuge.ministroUad ? 'SI' : 'NO'}
                className={classes.radioButtonSexo}
                onChange={handleChangeMinistroUADRadio}>
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
            </RadioGroup>
        </FormControl>
    </Box>

}