import React, {useContext, useState} from 'react'
import { TextField,Typography,RadioGroup,Radio,FormControlLabel,Button,Box,Grid,Switch} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import { useEffect } from 'react';
import {ValidacionPago} from './index'
import {TipoPagoRealizado} from './TipoPagoRealizado'
import {DatosPersonalesMinExterno} from './DatosPersonalesMinExterno'
import {DatosPersonalesConyuge} from './DatosPersonalesConyuge'
import {NumeroReferencia} from './NumeroReferencia'
import {Alert} from '@mui/material'
import { FOCUSABLE_SELECTOR } from '@testing-library/user-event/dist/utils';
import Swal from 'sweetalert2';
import {Dialogos} from './Dialogos'
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/EditOutlined';
import PersonIcon from '@mui/icons-material/PersonOutline';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NumbersIcon from '@mui/icons-material/Numbers';
import {CabeceraConyuge} from './CabeceraConyuge'
import {Contingente} from './Contingente'
import {Ubicacion} from './Ubicacion'

const regex_solo_numeros = /^[0-9\b]+$/;

export const Inscripcion = ()=>{
    const { datos,
            handleChangeMinistroUad,
            datosPagoOK,
            descripcionProvincia,
            handleChangeDocumento,
            infoMinistro,
            reinicializar,
            reinicializarConyuge,
            buscarDatosMinistro,
            buscarHabilitacionExterno,
            handleChangeConyugeMinistroUad,
            datosConyugeOk,
            datosMinistroOk,
            contingente,
            handleChangeProvincia,
            inscribir} = useContext(formularioContext)

    const [tipoMinistro,setTipoMinistro] = useState(null)
    const [abrirContingente,setAbrirContingente] = useState(false)

    const iniciarInscripcion = ()=>{

        if(!datosMinistroOk()[0]){
            alert(datosMinistroOk()[1])
            return 
        }else{
            if(datos.esMatrimonial){
                if(!datosConyugeOk()[0]){
                    alert(datosConyugeOk()[1])
                    return 
                }
            }
        }

        if(datos.contingente>1){
            setAbrirContingente(true)
            return
        }

        const textoConfirmacion = nombresSegunTipo(datos,infoMinistro)

        Swal.fire({
            text:textoConfirmacion,
            showCancelButton:true,
            confirButtonText:'Si, inscribir',
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    inscribir();
                }
            }
        )
    }

    const inscribirContingente = ()=>{
        let pregunta = ''

        if(datos.contingente >(contingente.length)){
            pregunta = `<div> 
                <p>El cupón admite ${datos.contingente} persona/s</p>
                <p>Va a inscribir un grupo de solo ${contingente.length} persona${contingente.length>0 ? 's' : ''}</p>
                <p>¿Confirma la inscripción?</p>
            </div>`
        }else{
            pregunta = `<div> 
            <p>¿Confirma la inscripción de ${contingente.length} persona/s</p>
        </div>`
        }
        
        Swal.fire({
            html:pregunta,
            showCancelButton:true,
            confirButtonText:'Si, inscribir',
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    inscribir();
                }
            }
        )
    }


    return <><Box sx={{ display:'flex',flexDirection:'column',alignItems:'center',marginTop:'0rem' }}> 
        
        {!datosPagoOK()[0] && <>
            {!datos.tipoPago && <TipoPagoRealizado/>}
            {datos.tipoPago && <NumeroReferencia/>}
            {datos.tipoPago && datos.pago &&<AnalisisPago datosPagoOK ={datosPagoOK}/>}
        </>}

        {datosPagoOK()[0] && datos.contingente > 1  && <>
            <DetallesPago datos={datos} handleChangeProvincia={handleChangeProvincia}/>
            {datos.id_provincia && <Box sx={{padding:'2rem'}}>
                <Button onClick={()=>setAbrirContingente(true)} variant='contained' color='primary'>Continuar</Button>
            </Box>}
        </>}

        {datosPagoOK()[0] && datos.contingente < 2 && <Inscribir handleChangeMinistroUad={handleChangeMinistroUad} 
                                        datos={datos}
                                        inscribir={iniciarInscripcion}
                                        datosConyugeOk = {datosConyugeOk}
                                        reinicializar = {reinicializar}
                                        datosMinistroOk = {datosMinistroOk}
                                        handleChangeConyugeMinistroUad = {handleChangeConyugeMinistroUad}
                                        descripcionProvincia={descripcionProvincia}
                                        buscarDatosMinistro = {buscarDatosMinistro}
                                        buscarHabilitacionExterno={buscarHabilitacionExterno}
                                        reinicializarConyuge = {reinicializarConyuge}
                                        handleChangeDocumento={handleChangeDocumento}/> }

        {/*<Typography variant="caption">¿Es ministro asociado a la UAD?</Typography>
        <Box sx={{marginTop:'1rem'}}>
            <TextField autoFocus fullWidth id="ub-dni" inputProps={{maxLength: 12,placeholder:textoSegunTipoPago(datos.tipoPago)}} label="Ingrese su DNI" variant="outlined" />
        </Box>*/}

<Dialogos open={abrirContingente} 
                    titulo='Datos del contingente' 
                    textoAceptar='Inscribir'
                    procesarResultado={inscribirContingente}
                    texto = {`El cupón permite inscribir ${datos.contingente} personas`}
                    procesarCancelar = {()=>setAbrirContingente(false)}
                   >
              <Contingente/>
            </Dialogos>

</Box>
</>
}

const textoSegunTipoPago = (tipoPago) =>{
    return tipoPago=='mercadopago' ? 'Número de operación' : 'Número de recibo'
}

const indicacionSegunTipoPago = (tipoPago) =>{
    return tipoPago=='mercadopago' ? 'Ingrese el número de operación de mercadopago' : 'Para continuar ingrese el número de recibo emitido por la UAD'
}

const AnalisisPago = ({datosPagoOK})=>{
        return <Box>
                <Alert variant="outlined" severity={datosPagoOK()[0]? 'info' : 'error'}>
                    {datosPagoOK()[1]}
                </Alert>
          </Box>
}

const Inscribir = ({datos,
                    handleChangeMinistroUad,
                    handleChangeDocumento,
                    buscarDatosMinistro,
                    inscribir,
                    descripcionProvincia,
                    datosConyugeOk,
                    datosMinistroOk,
                    reinicializar,
                    handleChangeConyugeMinistroUad,
                    reinicializarConyuge,
                    buscarHabilitacionExterno})=>{
    const [iniciarBusquedaDatos,setIniciarBusquedaDatos]= useState(false)
    const [datosMinistroUad,setDatosMinistroUad]= useState(null)
    const [pedirDatosConyuge,setPedirDatosConyuge] = useState(false)
    const [pedirDatosMinExterno,setPedirDatosMinExterno] = useState(false)

    const cancelarBusquedaDatos = ()=>{
        setIniciarBusquedaDatos(false)
        setPedirDatosMinExterno(false)
        reinicializar()
    }

    const validarDNI = ()=>{
        buscarDatosMinistro() // sea ministro uad o no voy a verificar que el DNI está en la base de datos
        .then(info=>{
            if(!info){
                if(datos.ministroUad=='ministrouad'){
                    Swal.fire({
                        html:'<p>No encontramos el DNI en la base de datos UAD</p><p>Verifique que el número es correcto</p><p>Si posee credenciales UAD y sus datos no aparecen contacte a la mesa de ayuda</p>',
                        icon: 'warning',
                        customClass: 'slow-animation',
                        confirmButtonColor: '#3085d6',
                    })   
                }else{
                    buscarHabilitacionExterno(datos?.nro_opcion)
                    .then(resultado=>{
                        if(resultado){
                            setPedirDatosMinExterno(true)
                        }else{
                            Swal.fire({
                                html:'<p>El DNI ingresado no se encuentra autorizado</p><p>Verifique que el número es correcto</p><p>Si su DNI debiera figurar como autorizado por favor contacte a la mesa de ayuda</p>',
                                icon: 'warning',
                                customClass: 'slow-animation',
                                confirmButtonColor: '#3085d6',
                            })  
                        }
                    }).catch(err=>alert(err))
                }
            }else{
                if(datos.ministroUad=='ministrouad'){
                    setDatosMinistroUad(info)
                    setIniciarBusquedaDatos(true) // Avanzar al próximo paso para validar datos como ministro uad
                }else{
                    Swal.fire({
                        html:`<p>El DNI ingresado corresponde a un ministro UAD con credenciales (${info.nombre})</p>`,
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                    })   
                }
            }
        })
        .catch(err=>{
            Swal.fire({
                html:`<p>${err.message}</p>`,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            }) 
        })
    }

    const abrirFormConyuge = ()=>{
        setPedirDatosConyuge(true)
    }

    const abrirFormExterno = ()=>{
        setPedirDatosMinExterno(true)
    }

    const procesarMinistroExterno = ()=>{
        if(datosMinistroOk()[0]){
            setPedirDatosMinExterno(false)    
            setIniciarBusquedaDatos(true)
        }else{
            Swal.fire({
                html:`<p>${datosMinistroOk()[1]}</p>`,
                icon: 'warning',
                customClass: 'slow-animation',
                confirmButtonColor: '#3085d6',
            })   
        }
    }

    if (iniciarBusquedaDatos){
        return <>
            <DetallesPago datos={datos}/>
            <InscribirSegunTipoMinistro 
                datos={datos} 
                abrirFormConyuge={abrirFormConyuge}
                abrirFormExterno={abrirFormExterno}
                inscribir={inscribir}
                datosConyugeOk={datosConyugeOk}
                reinicializarConyuge={reinicializarConyuge}
                handleChangeConyugeMinistroUad = {handleChangeConyugeMinistroUad}
                descripcionProvincia={descripcionProvincia}
                datosMinistroUad={datosMinistroUad} 
                cancelar={cancelarBusquedaDatos}/>
             
             <Dialogos open={pedirDatosConyuge && !datos.conyuge.ministroUad} 
                    titulo='Datos del conyuge' 
                    soloCheckCerrar
                    procesarCancelar = {()=>setPedirDatosConyuge(false)}
                   >
              <DatosPersonalesConyuge/>
            </Dialogos>

            <FormularioMinistroExterno
                pedirDatosMinExterno = {pedirDatosMinExterno}
                procesarMinistroExterno = {procesarMinistroExterno} 
            />

        </>
    }

    return <Box>

        <DetallesPago datos={datos}/>
        <Typography style={{marginTop:'1rem',marginBottom:'1rem'}} variant="h6">Para continuar indique si tiene credenciales UAD. Luego ingrese su DNI</Typography>
        <RadioGroup
                    aria-labelledby="lbl-min-uad"
                    name="radio-min-uad"
                    value={datos.ministroUad}
                    onChange={handleChangeMinistroUad}>
                       <FormControlLabel value={'ministrouad'} control={<Radio />} label="Si, tengo credenciales UAD" />
                       <FormControlLabel value={'externo'} control={<Radio />} label="No, no tengo credenciales UAD" />
        </RadioGroup>

        {datos.ministroUad && <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <Box sx={{marginTop:'1rem'}}>
                    <TextField autoFocus value={datos.documento} onChange={handleChangeDocumento} fullWidth id="ub-dni" inputProps={{maxLength: 9,placeholder:'DNI'}} label="Ingrese su DNI" variant="outlined" />
            </Box>
            {datos.documento && <Box sx={{padding:'2rem'}}>
                    <Button disabled={datos.documento.trim()=='' || datos.documento.trim().length<7} onClick={validarDNI} variant='contained' color='primary'>Continuar</Button>
            </Box>}
        </Box>}

        <FormularioMinistroExterno
            pedirDatosMinExterno = {pedirDatosMinExterno}
            procesarMinistroExterno = {procesarMinistroExterno} 
            cancelarBusquedaDatos = {cancelarBusquedaDatos}
        />
        {/*<Dialogos open={pedirDatosMinExterno} 
                    titulo='Datos del ministro externo' 
                    procesarResultado={procesarMinistroExterno}
                    procesarCancelar = {cancelarBusquedaDatos}
                   >
              <DatosPersonalesMinExterno/>
        </Dialogos>*/}
    </Box>
}

const FormularioMinistroExterno = ({pedirDatosMinExterno,procesarMinistroExterno,cancelarBusquedaDatos})=>{
return <Dialogos open={pedirDatosMinExterno} 
                    titulo='Datos del ministro externo' 
                    procesarResultado={procesarMinistroExterno}
                    procesarCancelar = {cancelarBusquedaDatos || false}
                   >
              <DatosPersonalesMinExterno/>
</Dialogos>
}


const InscribirSegunTipoMinistro = ({datos,
                                    datosMinistroUad,
                                    cancelar,
                                    inscribir,
                                    descripcionProvincia,
                                    reinicializarConyuge,
                                    handleChangeConyugeMinistroUad,
                                    abrirFormConyuge,
                                    abrirFormExterno,
                                    datosConyugeOk})=>{

    if (datos.ministroUad=='ministrouad'){
        return <>

            <Typography style={{marginTop:'1rem',marginBottom:'1rem'}} variant="h6">
                Por favor revise los datos        
            </Typography>

            <Grid container style={{maxWidth:'800px'}}>
                    <Grid item xs={12} sm={datos.esMatrimonial ? 6 : 12} style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <DatosMinistro datosMinistroUad={datosMinistroUad}/>
                    </Grid> 
                    {datos.esMatrimonial && <Grid item xs={12} sm={6} style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <Matrimonio editar = {abrirFormConyuge} 
                                descripcionProvincia={descripcionProvincia} 
                                datos={datos}
                                reinicializarConyuge={reinicializarConyuge}
                                handleChangeConyugeMinistroUad={handleChangeConyugeMinistroUad}
                                />
                        <span style={{color:'red'}}>{datosConyugeOk()[1]}</span>
                    </Grid>}
            </Grid>
          <Box sx={{padding:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                    <Button sx={{padding:'2rem'}} onClick={cancelar} variant="outlined" color="error" startIcon={<CloseSharpIcon />}>Cancelar</Button>
                    {datosConyugeOk()[0] && <Button sx={{padding:'2rem'}} onClick={inscribir} variant='contained'  color='primary'  startIcon={<CheckIcon />}>{textoSegunTipoParticipante(datos,textoSegunTipoParticipante)}</Button>}
                    {!datosConyugeOk()[0] && <Alert title={datosConyugeOk()[1]} variant="outlined" severity={'warning'}>
                            <Box sx={{display:'flex'}}>
                                <p>Requiere la inscripción del conyuge</p>
                                <Button color='primary' onClick={abrirFormConyuge}>Editar</Button>
                            </Box>
                        </Alert>
                    } 
          </Box>
        </>
    }else{
        return <>

            <Typography style={{marginTop:'1rem',marginBottom:'1rem'}} variant="h6">
                Por favor revise los datos
            </Typography>

            
            <Grid container style={{maxWidth:'800px'}}>
                    <Grid item xs={12} sm={datos.esMatrimonial ? 6 : 12} style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <Externo editar={abrirFormExterno} descripcionProvincia={descripcionProvincia} datos={{edad:datos.edad,nombre:datos.nombre,apellido:datos.apellido,sexo:datos.sexo,id_provincia:datos.id_provincia,documento:datos.documento,email:datos.email}} titulo='Inscribir a:'/>
                    </Grid> 
                    {datos.esMatrimonial && <Grid item xs={12} sm={6} style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <Matrimonio editar = {abrirFormConyuge} 
                                descripcionProvincia={descripcionProvincia} 
                                datos={datos}
                                reinicializarConyuge={reinicializarConyuge}
                                handleChangeConyugeMinistroUad={handleChangeConyugeMinistroUad}
                                />
                        <span style={{color:'red'}}>{datosConyugeOk()[1]}</span>
                    </Grid>}
            </Grid>
            <Box sx={{padding:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                        <Button sx={{padding:'2rem'}} onClick={cancelar} variant="outlined" color="error" startIcon={<CloseSharpIcon />}>Cancelar</Button>
                        {datosConyugeOk()[0] && <Button sx={{padding:'2rem'}} onClick={inscribir} variant='contained'  color='primary'  startIcon={<CheckIcon />}>{textoSegunTipoParticipante(datos,textoSegunTipoParticipante)}</Button>}
                        {!datosConyugeOk()[0] && <Alert title={datosConyugeOk()[1]} variant="outlined" severity={'warning'}>
                            <Box sx={{display:'flex'}}>
                                <p>Requiere la inscripción del conyuge</p>
                                <Button color='primary' onClick={abrirFormConyuge}>Editar</Button>
                            </Box>
                            </Alert>
                        } 
            </Box>
            
        </>
    }
}

const DetallesPago = ({datos,handleChangeProvincia})=>{
    return <Alert variant="outlined" severity={'info'}>
    El pago {datos.pago[0].id} se encuentra aprobado
    <p><b>{datos.descripcion}</b></p>
    {datos.contingente > 0 && <p>Cupón para inscribir a <b>{datos.contingente}</b> persona/s</p>}
    {datos.contingente == 0 && <p>{datos.pago[0].moneda} {datos.monto_pagado} ({datos.pago[0].tipo_pago} / {datos.pago[0].metodo_pago})</p>}
    
    {datos.contingente > 0 && <div style={{fontSize:'small'}}>
        <p style={{fontWeight:'600'}}>Solicitante:</p>
        <p>{datos.pago[0].tipo_doc} : {datos.pago[0].numero_doc}</p>
        <p>E-mail: {datos.pago[0].email} </p>

        {/*Pido la ubicación solo cuando el cupón es para un contingente de más de 1 persona */}
        {datos.contingente > 1 && <Ubicacion id_provincia={datos.id_provincia} 
                    textoCustom ="Seleccione una provincia"
                    handleChangeProvincia={handleChangeProvincia}/>}
    </div>}

    {datos.contingente == 0 && <div style={{fontSize:'small'}}>
        <p>{datos.pago[0].tipo_doc}/{datos.pago[0].numero_doc} / {datos.pago[0].email}</p>
    </div>}

</Alert>
}


const Matrimonio = ({datos,descripcionProvincia,editar,handleChangeConyugeMinistroUad,reinicializarConyuge})=>{
    return <> 
        <Grid container style={{border:'solid 1px lightgray',borderRadius:'10px',maxWidth:'300px',justifyContent:'center',padding:'0.5rem'}}>
            <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px gray'}}>
                {/*<EdicionConyuge editar={editar} 
                    datos={datos} 
                    handleChangeConyugeMinistroUad={handleChangeConyugeMinistroUad}
                    reinicializarConyuge={reinicializarConyuge}
                />*/}
                    <CabeceraConyuge editar={editar} />
            </Grid>  
               <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                    <Box sx={{display:'flex',justifyContent:'space-between'}}>
                        <PersonIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                        <Typography variant='subtitle1'>{datos.conyuge.ministroUad ? datos.conyuge.nombre.toUpperCase() : `${datos.conyuge?.nombre}, ${datos.conyuge?.apellido}`}</Typography>
                        {!datos.conyuge.ministroUad && <Button onClick={editar} size='small' variant='outlined' color='primary' startIcon={<EditIcon/>}>Editar</Button>}
                    </Box>
               </Grid> 
               <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                    <Box sx={{display:'flex'}}>
                        <BadgeIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                        <Typography variant="subtitle1">{datos.conyuge?.documento}</Typography>
                    </Box>
               </Grid> 
               <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                    <Box sx={{display:'flex'}}>
                        <NumbersIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                        <Typography variant="subtitle1">{datos.conyuge?.edad!='' ? `${datos.conyuge?.edad} años` : ''}</Typography>
                    </Box>
               </Grid>                  
               <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                   <Box sx={{display:'flex'}}>
                        <LocationOnIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                        <Typography variant="subtitle1">{descripcionProvincia(datos.conyuge?.id_provincia)}</Typography>
                    </Box>
               </Grid> 
               <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                    <Box sx={{display:'flex'}}>
                        <AlternateEmailIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                        <Typography variant="subtitle1"> {datos.conyuge?.email}</Typography>
                    </Box>
               </Grid>
          </Grid> 
    </>
}

const Externo = ({datos,descripcionProvincia,editar,titulo})=>{
    return <>
        <Grid container style={{border:'solid 1px lightgray',borderRadius:'10px',maxWidth:'300px',justifyContent:'center',padding:'0.5rem'}}>
            <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px gray'}}>
                <Box sx={{display:'flex',justifyContent:'space-between',marginBottom:'0.5rem'}}>
                    <Typography style={{}} variant="h6">
                        {titulo}
                    </Typography>
                    {/*<Button onClick={editar} size='small' variant='outlined' color='primary' startIcon={<EditIcon/>}>Editar</Button>*/}
                </Box>
            </Grid>  
               <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                    <Box sx={{display:'flex',justifyContent:'space-between'}}>
                        <PersonIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                        <Typography variant='subtitle1'>{`${datos.nombre} ${datos.apellido}`}</Typography>
                        <Button onClick={editar} size='small' variant='outlined' color='primary' startIcon={<EditIcon/>}>Editar</Button>
                    </Box>
               </Grid> 
               <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                    <Box sx={{display:'flex'}}>
                        <BadgeIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                        <Typography variant="subtitle1">{datos.documento}</Typography>
                    </Box>
               </Grid> 
               <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                    <Box sx={{display:'flex'}}>
                        <NumbersIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                        <Typography variant="subtitle1">{datos.edad!='' ? `${datos.edad} años` : ''}</Typography>
                    </Box>
               </Grid>                
               <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                   <Box sx={{display:'flex'}}>
                        <LocationOnIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                        <Typography variant="subtitle1">{descripcionProvincia(datos.id_provincia)}</Typography>
                    </Box>
               </Grid> 
               <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                    <Box sx={{display:'flex'}}>
                        <AlternateEmailIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                        <Typography variant="subtitle1"> {datos.email}</Typography>
                    </Box>
               </Grid>
          </Grid> 
    </>
}

const DatosMinistro = ({datosMinistroUad,editar})=>{
    return <Grid container style={{border:'solid 1px lightgray',borderRadius:'10px',maxWidth:'350px',justifyContent:'center',padding:'0.5rem'}}>
    
    <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px gray'}}>
            <Box sx={{display:'flex',justifyContent:'space-between',marginBottom:'0.5rem'}}>
                <Typography style={{}} variant="h6">
                    Inscribir a:
                </Typography>
                {editar && <Button onClick={editar} size='small' variant='outlined' color='primary' startIcon={<EditIcon/>}>Editar</Button>}
            </Box>
    </Grid>                     
    <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
            <Box sx={{display:'flex'}}>
                <PersonIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                <Typography variant='subtitle1'>{datosMinistroUad.nombre.toUpperCase()}</Typography>
            </Box>
    </Grid> 
    <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
        <Box sx={{display:'flex'}}>
            <BadgeIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
            <Typography variant="subtitle1">{datosMinistroUad.nro_documento}</Typography>
        </Box>
    </Grid> 
    <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
        <Box sx={{display:'flex'}}>
            <NumbersIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
            <Typography variant="subtitle1">{datosMinistroUad.edad!='' ? `${datosMinistroUad.edad} años` : ''}</Typography>
        </Box>
    </Grid> 
    <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
        <Box sx={{display:'flex'}}>
            <LocationOnIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
            <Typography variant="subtitle1"> {datosMinistroUad.provincia}</Typography>
        </Box>
    </Grid> 
    <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
        <Box sx={{display:'flex'}}>
            <AlternateEmailIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
            <Typography variant="subtitle1"> {datosMinistroUad.email}</Typography>
        </Box>
    </Grid>
</Grid>
}

const nombresSegunTipo = (datos,infoMinistro) =>{
    let nombre_1

    if(datos.ministroUad=='ministrouad'){
        nombre_1 = infoMinistro.nombre.toUpperCase()
    }else{
        nombre_1 = `${datos.apellido}, ${datos.nombre}`
    }

    const textoConfirmacion = datos.esMatrimonial ? 
    `¿Confirma la inscripción de : ${nombre_1} y ${datos.conyuge.apellido}, ${datos.conyuge.nombre}?`
    : `¿Confirma la inscripción de : ${nombre_1}?`

    return textoConfirmacion
}

const textoSegunTipoParticipante = (datos,tipoParticipante) =>{

    if (tipoParticipante=='general'){
        return `Los datos son correctos`
    }else{
        if(datos.contingente==1){
            return `Los datos son correctos`
        }else if (datos.contigente==0){
            return `Los datos son correctos`
        }else{
            return `Continuar`
        }
    }
}