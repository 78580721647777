import React, {useContext, useEffect, useRef,useState} from 'react'
import {TextField, 
        Button, 
        Box ,
        Typography,
        Switch,
        FormGroup,
        FormControlLabel,
        Select,makeStyles ,
        InputLabel,
        MenuItem,
        FormControl,
        RadioGroup,
        Radio,
        FormLabel,InputAdornment } from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


import {Dialogos} from './Dialogos'


export const Ubicacion = ({id_provincia,handleChangeProvincia,textoCustom})=>{
    const {provincias} = useContext(formularioContext)

    return <Box sx={{display:'flex', flexDirection:'column'}}> 

        <Box sx={{marginTop:'2rem'}}>
            <InputLabel style={{textAlign:'left'}} id="lbl-sexo">{textoCustom || 'Provincia'}</InputLabel>
            <FormControl fullWidth>
                    <Select fullWidth
                        labelId="select-label-provincia"
                        id="sl-provincia"
                        value={id_provincia}
                        label="Provincia"
                        onChange={handleChangeProvincia}
                    >
                        {provincias.map(item=>{
                            return <MenuItem value={item.id_provincia}>{item.nombre}</MenuItem>
                        })}

                    </Select>
            </FormControl>
        </Box>
     </Box>
}