import React, { useEffect,useState } from "react"
import Axios from 'axios'

export const useTablasGenerales = ()=>{
    const [mensaje,setMensaje] = useState([])
    const [cargando, setCargando] = useState(false);
    const [eventos,setEventos] = useState([])
    const [eventosValor,setEventosValor] = useState([])
    const [provincias,setProvincias] = useState([])
    const [error,setError] = useState(null)

    useEffect(()=>{
        const llamarapi =  async ()=>{
            setCargando(true)
            try{
                const {data} = await Axios.get(process.env.NODE_ENV =='development' ? 'http://localhost:3020/api/usuarios/test':'https://uadapi.uad.digital/api/usuarios/test')
                    
                    setMensaje(data.mensaje)
                    setCargando(false)

            }catch(err){
                alert('1 Tenemos un problema de conexión. Por favor intentá más tarde o comunicate con la mesa de ayuda o la oficina UAD')
                setCargando(false)
                setError('Error al cargar datos generales (1)')
            }
    
        }

        const cargarTablas =  async ()=>{
            setCargando(true)
            try{
                const vector = await Promise.all([
                                    Axios.get(process.env.NODE_ENV =='development' ? 'http://localhost:3020/api/tablasgenerales/eventos':'https://uadapi.uad.digital/api/tablasgenerales/eventos'),
                                    Axios.get(process.env.NODE_ENV =='development' ? 'http://localhost:3020/api/tablasgenerales/provincias':'https://uadapi.uad.digital/api/tablasgenerales/provincias'),
                                    Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/tablasgenerales/eventosvalor/${process.env.REACT_APP_ID_EVENTO}`:`https://uadapi.uad.digital/api/tablasgenerales/eventosvalor/${process.env.REACT_APP_ID_EVENTO}`)
                                ])
                    
                    setEventos(vector[0].data)
                    setProvincias(vector[1].data.filter(item=>item.id_provincia>0))
                    setEventosValor(vector[2].data)
                    setCargando(false)

               }catch(err){
                alert('1 Tenemos un problema de conexión. Por favor intentá más tarde o comunicate con la mesa de ayuda o la oficina UAD')
                setCargando(false)
                setError('Error al cargar datos generales (2)')
            }
    
        }

        //llamarapi()
        cargarTablas()
    },[])

    return {mensaje,
            cargando,
            eventos,eventosValor,provincias,
            error}
}