import React, {useContext,useState,useEffect} from 'react'
import {TextField, Grid, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import {DatosPersonales} from '../componentes/DatosPersonales'


export const DatosPersonalesMinExterno = ()=>{
    const { datos,
        handleChangeApellido,
        handleChangeDocumento,
        handleChangeSexo,
        handleChangeProvincia,
        datosMinistroOk,
        handleChangeEdad,
        handleChangeEmail,
        handleChangeNombre} = useContext(formularioContext)
        
    const useStyle = makeStyles({
        selectpais: {
            background:datos.pais > 0 ? 'white' : 'gray',
            color:'black'
        },
        selectprovincia: {
            background:datos.provincia > 0 ? 'white' : 'gray',
            color:'black'
        },
      });

    const classes = useStyle();

    return <DatosPersonales  handleChangeApellidoDinamico={handleChangeApellido}
    handleChangeDocumentoDinamico = {handleChangeDocumento}
    handleChangeSexoDinamico = {handleChangeSexo}
    handleChangeProvinciaDinamico = {handleChangeProvincia}
    datosOkDinamico = {datosMinistroOk}
    handleChangeEmailDinamico = {handleChangeEmail}
    handleChangeEdadDinamico = {handleChangeEdad}
    handleChangeNombreDinamico = {handleChangeNombre}
    datosDinamicos = {{nombre:datos.nombre,
                        apellido:datos.apellido,
                        sexo:datos.sexo,
                        edad:datos.edad,
                        documento:datos.documento,
                        id_provincia:datos.id_provincia,
                        email:datos.email}}
    />
}