import React, {useContext, useState} from 'react'
import { TextField,Button,Typography,Radio,FormLabel,Tooltip,Box} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import { useEffect } from 'react';
import {ValidacionPago} from './index'
import {Alert} from '@mui/material'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import {Dialogos} from './Dialogos'
import {AyudaNP} from './AyudaNP'

import nro_operacion_img from '../numero_operacion.PNG'; 

const regex_solo_numeros = /^[0-9\b]+$/;

export const NumeroReferencia = ()=>{
    const { datos,handleChangeReferencia,tipoParticipante,nro_cupon} = useContext(formularioContext)
    const [ayuda, setAyuda] = useState(false);


    if (tipoParticipante=='contingente'){
        return <>
            <Box sx={{ display:'flex',flexDirection:'column',alignItems:'center',marginTop:'0rem' }}> 
            {/*<Typography style={{marginTop:'0rem',marginBottom:'0rem'}} variant="h6">{indicacionSegunTipoPago(datos.tipoPago)}</Typography>*/}
            {!nro_cupon && <Alert sx={{justifyContent:'center'}} severity="xxx" variant='xxx'>
                Ingrese el código de su cupón
            </Alert>}
            
            <Box sx={{marginTop:'1rem'}}>
                <TextField autoFocus fullWidth id="ub-referencia" disabled={nro_cupon && nro_cupon!=''} inputProps={{maxLength: 7,placeholder:'Código de cupón'}} value={datos.referencia} onInput={handleChangeReferencia} label="Código de cupón" variant="outlined" />
            </Box>
           
            {datos.referencia.trim().length<7 && <Alert sx={{justifyContent:'center'}} severity="error">El código del cupón tiene 2 letras y 5 números</Alert>}
            
            <ValidacionPago/>

        </Box>
        </>
    }

    return <><Box sx={{ display:'flex',flexDirection:'column',alignItems:'center',marginTop:'0rem' }}> 
        {/*<Typography style={{marginTop:'0rem',marginBottom:'0rem'}} variant="h6">{indicacionSegunTipoPago(datos.tipoPago)}</Typography>*/}
        <Alert sx={{justifyContent:'center'}} severity="xxx" variant='xxx'>
            Ingrese el número de operación de mercadopago
        </Alert>
        
        <Box sx={{marginTop:'1rem'}}>
            <TextField autoFocus fullWidth id="ub-referencia" inputProps={{maxLength: 12,placeholder:textoSegunTipoPago(datos.tipoPago)}} value={datos.referencia} onInput={handleChangeReferencia} label={textoSegunTipoPago(datos.tipoPago)} variant="outlined" />
        </Box>
        
        <Button style={{textTransform: 'none'}} onClick={()=>setAyuda(!ayuda)} size="small" color='primary' variant='text'>¿Qué es el número de operación?</Button>
        
        {datos.referencia.trim().length<10 && <Alert sx={{justifyContent:'center'}} severity="error">El número debe tener entre 10 y 12 dígitos</Alert>}
        
        <ValidacionPago/>

        <AyudaNP ayuda={ayuda} setAyuda={setAyuda}/> 

        <div>
     
    </div>
</Box>
</>
}



const textoSegunTipoPago = (tipoPago) =>{
    return tipoPago=='mercadopago' ? 'Número de operación' : 'Número de recibo'
}

const indicacionSegunTipoPago = (tipoPago) =>{
    return tipoPago=='mercadopago' ? 'Ingrese el número de operación de mercadopago:' : 'Para continuar ingrese el número de recibo emitido por la UAD:'
}

