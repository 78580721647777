import React, {useContext,useEffect,useState} from 'react'
import {Button, TextField, Box ,Typography,Paper,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/EditOutlined';
import PersonIcon from '@mui/icons-material/PersonOutline';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NumbersIcon from '@mui/icons-material/Numbers';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {Sexo} from './Sexo'
import {Alert} from '@mui/material'
import {useHelper} from '../hooks/useHelper'

//import PersonIcon from '@mui/icons-material/Person';
const regex_solo_numeros = /^[0-9\b]+$/;

let inscriptos = []

/*const inscriptos = [{nombre:'PABLO STOLARCZUK',edad:48,dni:23865621,sexo:'Hombre'},
{nombre:'PRISCILA MOYANO',edad:25,dni:3161222,sexo:'Mujer'},
{nombre:'WALTER RODRIGUEZ',edad:31,dni:6562121,sexo:'Hombre'},
{nombre:'SAMANTA GUTIERREZ',edad:25,dni:4312621,sexo:'Mujer'},
{nombre:'CARLOS SOKOLUK',edad:30,dni:3615621,sexo:'Hombre'},]
*/

export const Contingente = ()=>{
    const {errorMail,datos,descripcionProvincia,infoMinistro,contingente,updateContingente} = useContext(formularioContext)
    const [agregar,setAgregar]=useState(false)
    const {hacerScroll} = useHelper()

    return <Box sx={{display:'flex',alignSelf:'start',flexDirection:'column',alignItems:'left',marginTop:'1rem', marginBottom:'1rem' }}> 
           {/*!datos.esMinistroUad && <Externo datos={datos} descripcionProvincia={descripcionProvincia} />*/}
           {/*datos.esMinistroUad && <DatosMinistro datos={datos} descripcionProvincia={descripcionProvincia} />*/}
    <TableContainer component={Paper}>
      <Table id="contingente" aria-label="simple table">
      {contingente.length > 0 && <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell align="left">DNI</TableCell>
            <TableCell align="left">Edad</TableCell>
            <TableCell align="left">Sexo</TableCell>
          </TableRow>
        </TableHead>}
        <TableBody>
          {/*<PrimeraLinea datos={datos} infoMinistro={infoMinistro} contingente={contingente} descripcionProvincia={descripcionProvincia}/>*/}
          {contingente.map((row,index) => (
            <>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
            >
              <TableCell align="left"><Typography variant="h6">{index+1}</Typography>
              <Paper elevation={3} />
              </TableCell>
              <TableCell align="left"><IconButton onClick={()=>updateContingente(row,true)}><DeleteIcon /></IconButton></TableCell>
              <TableCell align="left">{row?.nombreCompleto ? row?.nombreCompleto : row.nombre}</TableCell>
              <TableCell align="left">{row?.nro_documento}</TableCell>
              <TableCell align="left">{row?.edad}</TableCell>
              <TableCell align="left">{row?.sexo}</TableCell>
            </TableRow>
            </>
          ))}
          {!agregar && contingente.length > 0 && <TableRow>
                <TableCell colSpan="6" align="left">
                    <Typography align='right'>{contingente.length} participantes de {datos.contingente}</Typography>    
                </TableCell>
          </TableRow>}
          {!agregar && datos.contingente > contingente.length && <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 },cursor:'pointer'}}
            >

                <TableCell colSpan="5" align="left"><Button onClick={()=>setAgregar(true)} variant='outlined' color='secondary'>Agregar {contingente.length==0 ? 'una' : 'otra'} persona +</Button></TableCell>
            </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
    {agregar && <Nuevo hacerScroll = {hacerScroll} solicitante={datos} cantidad_permitida={datos.contingente} contingente={contingente} update={updateContingente} cancelar={()=>setAgregar(false)} errorMail={errorMail}/>}

  </Box>   
}

const PrimeraLinea = ({datos,infoMinistro,contingente,descripcionProvincia})=>{
    
    if (datos.ministroUad=='ministrouad'){
        return <><TableRow title='Solicitante'
        sx={{ background:'lightsteelblue',cursor:'pointer',verticalAlign:'middle'}}
        >
            <TableCell  align="left"><Typography variant="h6">1</Typography></TableCell>
            <TableCell sx={{ verticalAlign:'middle'}} align="left"><IconButton title='Solicitante'><PersonIcon /></IconButton></TableCell>
            <TableCell align="left">
                <Box>
                    <Typography  style={{ fontWeight: 600,background:'green',color:'white',padding:'1px',textAlign:'center' }} variant="caption" display="block" gutterBottom>
                                Solicitante
                    </Typography>
                    <span>{infoMinistro?.nombre.toUpperCase()}</span>
                    <p style={{padding:'0px'}}>{infoMinistro.email}</p>
                </Box>
            </TableCell>
            <TableCell align="left">{infoMinistro?.nro_documento}</TableCell>
            <TableCell align="left">{infoMinistro?.edad}</TableCell>
            <TableCell align="left">{infoMinistro?.sexo}</TableCell>
        </TableRow> 
         <TableRow>
               <TableCell colSpan="6"><Typography variant='body2' style={{fontWeight:'600'}}> Otros participantes ({contingente.length}) :</Typography></TableCell>
         </TableRow>
         </>   
  }

    return <><TableRow title='Solicitante'
            sx={{ background:'lightsteelblue',cursor:'pointer',verticalAlign:'middle'}}
                >
                    <TableCell  align="left"><Typography variant="h6">1</Typography></TableCell>
                    <TableCell sx={{ verticalAlign:'middle'}} align="left"><IconButton title='Solicitante'><PersonIcon /></IconButton></TableCell>
                    <TableCell align="left">
                        <Box>
                            <span>{datos.nombre}, {datos.apellido}</span>
                            <Typography  style={{ fontWeight: 600,background:'green',color:'white',padding:'1px',textAlign:'center' }} variant="caption" display="block" gutterBottom>
                                Solicitante
                            </Typography>
                            {datos.email}
                        </Box>
                    </TableCell>
                    <TableCell align="left">{datos.documento}</TableCell>
                    <TableCell align="left">{datos.edad}</TableCell>
                    <TableCell align="left">{datos.sexo}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell colSpan="6"><Typography variant='body2' sx={{fontWeight:'700'}}> Otros participantes ({contingente.length}) :</Typography></TableCell>
        </TableRow>
        </>
}


const Nuevo = ({update,cancelar,contingente,cantidad_permitida,hacerScroll,solicitante})=>{
    
    const [datos,setDatos] =  useState({
        nombre:'',
        apellido:'',
        nro_documento:'',
        edad:'',
        sexo:'M',
        nombreCompleto:''
    })

    useEffect(()=>{
        hacerScroll('newperson-1')
    },[])

    useEffect(()=>{
        setDatos({...datos,nombreCompleto:`${datos.apellido}, ${datos.nombre}`})
    },[datos.nombre,datos.apellido])

    const handleChangeNombre = (e)=>{
        setDatos({...datos,nombre:e.target.value.trim().toUpperCase()})
    }

    const handleChangeApellido = (e)=>{
        setDatos({...datos,apellido:e.target.value.trim().toUpperCase()})
    }

    const handleChangeDni = (e)=>{
        if(regex_solo_numeros.test(e.target.value.trim()) || e.target.value.trim()==''){
            setDatos({...datos,nro_documento:e.target.value.trim()});
        }
    }

    const handleChangeEdad = (e)=>{
        if(regex_solo_numeros.test(e.target.value.trim()) || e.target.value.trim()==''){
            setDatos({...datos,edad:e.target.value.trim()});
        }
    }

    const handleChangeSexo = (e) => {
        setDatos({...datos,sexo:e.target.value});
    };
   
    const iniciarGrabar = ()=>{
        if(datosMinistroOk()[0]){
            update(datos,false)
            .then((respuesta)=>{
                if(respuesta){
                    cancelar()
                }
            })
        }else{
            alert(datosMinistroOk()[1])
        }

    }

    const datosMinistroOk = ()=>{

            if(datos.nombre.trim()==''){
                return [false,`Falta completar el nombre`]
            }
    
            if(datos.apellido.trim()==''){
                return [false,`Falta completar el apellido`]
            }
    
            if(!datos.sexo || datos.sexo.trim()==''){
                return [false,`Falta completar el género`]
            }

            if(datos.nro_documento.trim()==''){
                return [false,`Falta completar el número de documento`]
            }

            if(datos.nro_documento.trim().length>9){
                return [false,`El número de documento es demasiado largo`]
            }

            if(datos.nro_documento.trim().length<7){
                return [false,`El número de documento requiere al menos 7 dígitos`]
            }
            
            if(!datos.edad || Number(datos.edad)==0){
                return [false,`Falta completar la edad`]
            }

            const edad2 = Number(datos.edad) 

            if(edad2>3 && edad2<100){
            }else{
                return [false,`Edad debe ser entre 4 y 99 años`]
            }

        return [true,null]
    }

    return <Box sx={{border:'solid 1px gray', borderRadius:'10px',padding:'2px'}}>
        Participante {contingente.length+1} de {cantidad_permitida} 
    <Typography id="newperson-1" style={{ fontWeight: 600,marginTop:'1rem',background:'#3f51b5',color:'white',paddingLeft:'0.5rem' }} variant="caption" display="block">
                                Ingrese una nueva persona:
                    </Typography>
    <TableContainer>
    <Table>
    <TableBody>
    <TableRow title='Solicitante'
                sx={{ background:''}}
                >
                    <TableCell sx={{verticalAlign:'top'}} colSpan="6" align="left">
                        <Box sx={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{display:'flex'}}>
                                <TextField 
                                style={{fontSize:'small'}}
                                fullWidth inputProps={{maxLength: 25}} id="dp-nombre" autoFocus 
                                value={datos.nombre}
                                onChange={handleChangeNombre} label="Nombre" variant="filled" />

                                <TextField fullWidth inputProps={{maxLength: 25}} id="dp-apellido"  
                                value={datos.apellido} 
                                onChange={handleChangeApellido} label="Apellido" variant="filled" />
                                
                                <TextField inputProps={{maxLength: 9}} id="dp-dni"  
                                value={datos.nro_documento} 
                                fullWidth
                                onChange={handleChangeDni} label="DNI" variant="filled" />
                            </Box>
                           
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <TextField inputProps={{maxLength: 2}} id="dp-edad"  
                                value={datos.edad} 
                                onChange={handleChangeEdad} label="Edad" variant="filled" />
                                <Box sx={{marginLeft:'3rem'}}>
                                    <Sexo sexo={datos.sexo} handleChangeSexo={handleChangeSexo}/>
                                </Box>
                        </Box>
                    </TableCell>
        </TableRow>
        <TableRow colSpan="4">
            <Box sx={{display:'flex',justifyContent:'end'}}>
            {!datosMinistroOk()[0] && <Alert  severity={datosMinistroOk()[0]? 'info' : 'error'}>
                        {datosMinistroOk()[1]}
            </Alert>}
                    <Button style={{margin:'1rem'}} variant='contained' color='primary' onClick={iniciarGrabar}>Agregar</Button>
                    <Button variant='outlined' onClick={cancelar}>Cancelar</Button>

            </Box>
        </TableRow>
       
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
}

const primeraLineaInscriptos = (datos,infoMinistro)=>{
    
    if(!datos){
        return
    }

    if (datos.ministroUad=='ministrouad'){
        return {nombre:infoMinistro?.nombre.toUpperCase(),
            dni:infoMinistro?.nro_documento,
            edad:infoMinistro?.edad,
            id_provincia:infoMinistro.id_provincia,
            email:infoMinistro.email,
            sexo:infoMinistro?.sexo}
    }else{
        return{
            nombre: `${datos.nombre} ${datos.apellido}`,
            dni:datos.documento,
            edad:datos.edad,
            id_provincia:datos.id_provincia,
            email:datos.email,
            sexo:datos.sexo
        }
    }

}