import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,Switch,Typography,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import {Dialogos} from './Dialogos'
import EditIcon from '@mui/icons-material/EditOutlined';
import {Alert} from '@mui/material'

export const CabeceraConyuge = ({editar})=>{
    const { datos,
        handleChangeConyugeMinistroUad,
        handleChangeDocumentoConyuge,
        reinicializarConyuge,
        buscarDatosConyuge,
        datosConyugeOk
        } = useContext(formularioContext)
const [resultado,setResultado] = useState(null)

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    useEffect(() => {

      if(!datos.conyuge.ministroUad){ // significa que había marcado como ministro uad y luego lo desmarqué

        if(resultado){ // significa que ya hice una búsqueda por dni de ministro uad y trajo algo
            editar() // la idea es que si seleccioné un ministro uad, lo trajo y luego lo desmarco que me abra solo en ese caso el formulario de edición
        }
      }
    }, [datos.conyuge.ministroUad])
    
    const cancelarBuscarDniConyuge =()=>{
        setResultado(null)
        reinicializarConyuge()
    }

    const iniciarBuscarDatosConyuge = ()=>{

        if(datos.documento.trim()==datos.conyuge.documento.trim()){
            alert('El DNI debe ser diferente del conyuge')
            return
        }

        setResultado(null)

        buscarDatosConyuge()
        .then(info=>{
            if(info){
                setResultado(true)
            }else{
                setResultado(false)
            }
        })
        .catch(err=>{
            alert(err)
        })
    }

    return  <>
    <Box>
        <Box sx={{display:'flex',justifyContent:'space-between',marginBottom:'0.5rem'}}>
            <Typography style={{}} variant="h6">
                Conyuge 
            </Typography>
            {datos.ministroUad=='ministrouad' && <FormControlLabel control={<Switch checked={datos.conyuge.ministroUad} onChange={handleChangeConyugeMinistroUad} />} label={`Es ministro UAD`} />}
            {/*<Button onClick={editar} size='small' variant='outlined' color='primary' startIcon={<EditIcon/>}></Button>*/}
        </Box>
    </Box>
    <Dialogos open={datos.conyuge.ministroUad && !datosConyugeOk()[0]} 
                        titulo='Ingrese el DNI del conyuge' 
                        procesarResultado={iniciarBuscarDatosConyuge}
                        procesarCancelar = {cancelarBuscarDniConyuge}
                    >
                <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <Box sx={{marginTop:'1rem'}}>
                                <TextField autoFocus value={datos.conyuge.documento} onChange={handleChangeDocumentoConyuge} fullWidth id="ub-dni" inputProps={{maxLength: 9,placeholder:'DNI'}} label="Ingrese su DNI" variant="outlined" />
                                {resultado==false && <Alert variant="outlined" severity='error'>
                                    No se encontraron resultados para el DNI ingresado
                                </Alert>}
                        </Box>
                            {/*datos.conyuge.documento && <Box sx={{padding:'2rem'}}>
                                    <Button disabled={datos.conyuge.documento.trim()=='' || datos.conyuge.documento.trim().length<7} onClick={()=>alert('función validarDNI')} variant='contained' color='primary'>Continuar</Button>
    </Box>*/}
                </Box>
    </Dialogos>
</>
}