import React,{useState,useContext, useEffect}  from 'react'
import { formularioContext } from '../contextos/FormularioContext'
import {LayoutCards} from './LayoutCards'
import {GenericCard} from './GenericCard'
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import {useHistory,useParams} from 'react-router-dom'
import {Loader} from './Loader';
import Logo from './Logo';

export const Formulario = ({tipo})=>{
    const { cargando,procesando,cargarDatosCupon,setTipoParticipante,error,codigoFinal } = useContext(formularioContext)
    const parametros = useParams()

    useEffect(()=>{
        setTipoParticipante(tipo)
        if(parametros.nro_cupon && parametros?.nro_cupon?.trim()!=''){
            cargarDatosCupon(parametros.nro_cupon.trim())
        }
    },[])

    if (cargando) {
        return <div>
                 <Loader open={true} 
                    children={<Logo width='300px'/>}
                    texto='Cargando evento...'/>
                </div>  
    }

    if (procesando){
       return  <GenericCard titulo="..." 
                    id='tl-finalizar' 
                    mostrar={true}
                    error={true}
                    mensajeError = {'Procesando la solicitud...'}
                    subtitulo='Procesando...'
                    >
            </GenericCard>
    }

    if (typeof error === 'string' && error.includes('Error al cargar datos')){
        return  <Box sx={{padding:'',marginTop:'',marginBottom:''}}>
            <GenericCard titulo="..." 
                     id='tl-finalizar' 
                     mostrar={true}
                     error={true}
                     mensajeError = {'Por favor intenta más tarde o contacta a la oficina UAD'}
                     subtitulo='Lo sentimos, la plataforma no se encuentra disponible en este momento'
                     >
                        <CloudOffIcon sx={{ fontSize: 50 }}/>
             </GenericCard>
        </Box> 
        
     }

    /*if (codigoFinal || cerrar){
        return <GenericCard titulo="" 
        id='tl-finalizar' 
        mostrar={true}
      
        subtitulo=''
        >
            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <Alert severity="info" style={{fontSize:'large'}}>Listo, ahora comunicate para realizar el primer pago y completar la inscripción</Alert>
                <WhatsAppIcon color='success' fontSize="large" />
                <h2>15-5494-3001</h2>
                <Alert variant='filled' severity="info" style={{fontSize:'x-large'}}>Código de solicitud: {codigoFinal}</Alert>
                <h3>Muchas gracias!</h3>
            </Box>
            
        </GenericCard>
    }*/

    return <div>
        <p style={{fontFamily:'Pavanam',fontSize:'x-large'}}>
        </p>
        <LayoutCards parametros={parametros}/>
    </div>

}