import React, {useContext} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'

export const Sexo = ({sexo,handleChangeSexo})=>{

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        },
        radioButtonSexo:{
            display:'flex',
            flexDirection:'row'
        }
      });

    const classes = useStyle();

    return <Box sx={{marginTop:'1rem',textAlign:'left',marginBottom:'1rem'}}>
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={sexo}
                className={classes.radioButtonSexo}
                onChange={handleChangeSexo}>
                    <FormControlLabel value="M" control={<Radio />} label="Hombre" />
                    <FormControlLabel value="F" control={<Radio />} label="Mujer" />
            </RadioGroup>
        </FormControl>
    </Box>

}