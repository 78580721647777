import React, {useContext,useEffect} from 'react'
import {FormControlLabel,FormControl,RadioGroup,Radio,FormLabel,Box,Tooltip} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'


export const TipoPagoRealizado = ()=>{
    const { datos,handleChangeTipoPago,reinicializar,
        programas} = useContext(formularioContext)

        useEffect(()=>{
            reinicializar()
        },[])

    return <><Box sx={{ display:'flex',flexDirection:'column',alignItems:'center' }}> 

            <Box sx={{}}>
                <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            value={datos.tipoPago}
                            onChange={handleChangeTipoPago}>
                                    <FormControlLabel value="mercadopago" control={<Radio />} label="Pagué por Mercadopago"/>
                                    <FormControlLabel value="transferencia" control={<Radio />} label="Pagué por transferencia bancaria"/>
                        </RadioGroup>
                </FormControl>
            </Box>
        </Box>
        </>
}