import React, {useContext} from 'react'
import {TextField,Button,DialogTitle,DialogContentText,DialogContent,DialogActions,Dialog} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import LinearProgress from '@mui/material/LinearProgress';

export const Loader = ({open,
                          children,
                          texto})=>{
    const {datos,handleChangeCarrera} = useContext(formularioContext)
  
    return     <div>
    <Dialog fullScreen open={open} style={{opacity:'0.85'}}>
      <DialogContent style={{display:'flex',background:'steelblue',flexDirection:'column', justifyContent:'center',height:'100%',alignItems:'center'}}>
        <DialogContentText>
            <p style={{textAlign:'center'}}>{texto}</p>
        </DialogContentText>
        <LinearProgress/>
            {children}
      </DialogContent>
    </Dialog>
  </div>
}