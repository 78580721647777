import React, { useState} from 'react'
import {Dialogos} from './Dialogos'
import {Box,Button,TextField,Typography} from "@material-ui/core";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import WhatsappRoundedIcon from '@mui/icons-material/WhatsappRounded';

export const FueraDeServicio = ()=>{
    const [consulta,setConsulta] = useState(false)

    return <div style={{minHeight:'350px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
        
        <h1>Disculpe las molestias</h1>
        <h1>Estamos haciendo mantenimiento, por favor intente más tarde.</h1>
        
        <BuildCircleIcon sx={{ fontSize: 80 }} />

        <Dialogos open={consulta} 
                titulo='Mesa de ayuda' 
                closeHeader
                procesarCancelar = {()=>setConsulta(false)}
            >
        <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',textAlign:'center'}}>
                <a href="https://api.whatsapp.com/send?phone=541126188236" target="_blank">
                    <WhatsappRoundedIcon sx={{ fontSize: 60, color:'green' }}/>
                    <Typography style={{marginTop:'0.5rem',marginBottom:'0.5rem'}} variant="h6">Abrir el chat de whatsapp</Typography>
                    <Typography variant="caption">011 2618-8236</Typography>
                </a>
        </Box>
        </Dialogos>
        <Box sx={{padding:'2rem'}}>
                    <Button variant='text' style={{textTransform: 'none',color:'white'}} onClick={()=>setConsulta(true)} size="large">¿Necesita ayuda?</Button>
        </Box>
</div>

}

