import React, {useContext,useState,useEffect} from 'react'
import {TextField, Grid, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import {Nacionalidad} from '../componentes/Nacionalidad'
import {Fecha} from '../componentes/Fecha'
import {useHelper} from '../hooks/useHelper'
import {SexosSelect} from '../componentes/SexosSelect'
import {Sexo} from './Sexo'
import {Ubicacion} from './Ubicacion'
import {Alert} from '@mui/material'

export const DatosPersonales = ({
    handleChangeApellidoDinamico,
    handleChangeDocumentoDinamico,
    handleChangeSexoDinamico,
    handleChangeProvinciaDinamico,
    datosOkDinamico,handleChangeEmailDinamico,handleChangeEdadDinamico,
    handleChangeNombreDinamico,datosDinamicos})=>{

    const {errorMail,datos} = useContext(formularioContext)
        
    return <Box>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Box sx={{marginTop:'0.5rem'}}>
                    <TextField fullWidth inputProps={{maxLength: 25}} id="dp-nombre" autoFocus 
                    value={datosDinamicos?.nombre} 
                    onChange={handleChangeNombreDinamico} label="Nombre" variant="filled" />
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box sx={{marginTop:'0.5rem'}}>
                    <TextField fullWidth inputProps={{maxLength: 25}} id="dp-apellido" 
                    value={datosDinamicos?.apellido} 
                    onChange={handleChangeApellidoDinamico} label="Apellido" variant="filled" />
                </Box>
            </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6}>
                <Box sx={{marginTop:'0.5rem'}}>
                    <TextField fullWidth inputProps={{maxLength: 9}} id="dp-documento" 
                    value={datosDinamicos?.documento} 
                    onChange={handleChangeDocumentoDinamico} label="Nro. de documento" variant="filled" />
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box sx={{marginTop:'0.5rem'}}>
                    <Sexo sexo={datosDinamicos?.sexo} handleChangeSexo={handleChangeSexoDinamico}/>
                </Box>
            </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12}>
                <Box sx={{marginTop:'0.5rem'}}>
                    <TextField fullWidth inputProps={{maxLength: 2}} id="dp-edad" 
                    value={datosDinamicos?.edad} 
                    onChange={handleChangeEdadDinamico} label="Edad" variant="filled" />
                </Box>
            </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12}>
                    <Ubicacion id_provincia={datosDinamicos?.id_provincia} 
                    handleChangeProvincia={handleChangeProvinciaDinamico}/>
            </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12}>
                <TextField fullWidth 
                    id="con-email" 
                    type="email" 
                    inputProps={{maxLength: 200}} 
                    placeholder="nombre@ejemplo.com" 
                    value={datosDinamicos?.email} 
                    onChange={handleChangeEmailDinamico} 
                    label="E-mail" 
                    variant="outlined" />
                 {/*errorMail(datosDinamicos.email) && <p style={{color:'red'}}>{errorMail(datosDinamicos.email)}</p>*/}            
            </Grid>
         </Grid>


        {!datosOkDinamico()[0] && <Alert variant="outlined" severity={datosOkDinamico()[0]? 'info' : 'error'}>
                    {datosOkDinamico()[1]}
        </Alert>}
        </Box>
}