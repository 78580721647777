import React, {useContext, useState,useEffect} from 'react'
import {Box,Button} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import {Dialogos} from './Dialogos'
import Axios from 'axios'
import { tr } from 'date-fns/locale';
import {Alert} from '@mui/material'

export const ValidacionPago = ()=>{
    const {datos,buscarPagos2,error,tipoParticipante,buscarPagosCupon} = useContext(formularioContext)


    if(tipoParticipante=='contingente'){
        return  <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <Box sx={{padding:'2rem'}}>
                <Button onClick={datos.referencia.trim().length == 7 ? buscarPagosCupon : ()=>alert('El código de cupón tiene 2 letras y 5 números')} variant='contained' color='primary'>Iniciar inscripción</Button>
            </Box>
            {datos.pago==false && <Alert variant="outlined" severity="error">
                    No se encontró el pago con el código de cupón ingresado. Verifique que el código es correcto y vuelva a ingresarlo o contacte a la mesa de ayuda
            </Alert>}
            {error && error[0]=='validacion_pago' && <Alert sx={{justifyContent:'center'}} variant='outlined' severity="error">{error[1]}</Alert>}
        </Box>
    }

    return  <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <Box sx={{padding:'2rem'}}>
                    <Button disabledxxx={datos.referencia.trim().length<10} onClick={datos.referencia.trim().length >9 ? buscarPagos2 : ()=>alert('El número de operación de mercadopago es un número entre 10 y 12 dígitos')} variant='contained' color='primary'>Verificar el pago</Button>
                </Box>
                {datos.pago && datos.pago.length>1 && <Alert variant="outlined" severity="error">
                    Se encontraron más de 1 movimiento asociado a ese número. No sabemos cuál tomar para saber el status
                </Alert>}
                {datos.pago==false && <Alert variant="outlined" severity="error">
                        No se encontró el pago con el número de referencia ingresado. Verifique que el número es correcto y vuelva a ingresarlo o contacte a la mesa de ayuda
                </Alert>}
                {error && error[0]=='validacion_pago' && <Alert sx={{justifyContent:'center'}} variant='outlined' severity="error">{error[1]}</Alert>}
    </Box>
    
}

