import React, {useContext, useState,useEffect} from 'react'
import {Box,Button} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import {Dialogos} from './Dialogos'
import Axios from 'axios'
import { tr } from 'date-fns/locale';
import {Alert} from '@mui/material'
import nro_operacion_img from '../numero_operacion.PNG'; 
import CloseIcon from '@mui/icons-material/Close';

export const AyudaNP = ({ayuda,setAyuda})=>{
 return <Dialogos open={ayuda} 
    fullscreen
    titulo='Número de operación o referencia de pago' 
    procesarCancelar = {()=>setAyuda(false)}
    >
    <Button onClick={()=>setAyuda(false)} style={{display:'flex',marginRight:'auto',marginLeft:'auto'}}><CloseIcon/>Cerrar</Button>    

    <Alert onClick={()=>setAyuda(false)} sx={{justifyContent:'center',marginTop:'1rem'}} severity="info" variant='outlined'>
    <p>El número de operación tiene entre 10 y 12 dígitos, por ejemplo 25216315895. Lo encontrarás al finalizar el pago.</p>
    <p>También puede figurar como "Referencia de pago" en el mail que mercadopago te envía con los datos de tu compra.</p>
    <p>Puede copiar y pegar ese número en vez de ingresarlo manualmente</p>
    <img style={{marginTop:'1rem'}} src={nro_operacion_img} alt="Numero de operación" />;
    </Alert>
</Dialogos>
    
}

